import React from "react";

const Feature = () => {
  return (
    <section
      className="feature-section pt-100 rpt-80 pb-40 rpb-90"
      id="features"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="feature-image">
              <img src="/assets/images/feature/graph.png" alt="Graph Image" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="feature-content">
              <div className="section-heading mb-50">
                <i className="flaticon-file"></i>
                <span className="heading-title">Chức năng chính</span>
                <h2>FIT Zalo App mang đến cho bạn</h2>
              </div>
              <div>
                <div className="feature-item">
                  <div className="icon-box">
                    <img
                      src="/assets/images/feature/app-icon1.png"
                      alt="App Icon"
                    />
                  </div>
                  <div className="content-box">
                    <h6>📢 Quản Lý Truyền Thông</h6>
                    <p>
                      Quản lý bài viết, sự kiện, và banner dễ dàng, giúp bạn tạo
                      và quản lý nội dung trực quan cho ứng dụng của mình.
                    </p>
                  </div>
                </div>
                <div className="feature-item">
                  <div className="icon-box">
                    <img
                      src="/assets/images/feature/app-icon1.png"
                      alt="App Icon"
                    />
                  </div>
                  <div className="content-box">
                    <h6>🛒 Quản Lý Bán Hàng</h6>
                    <p>
                      Theo dõi đơn đặt hàng, và quản lý sản phẩm một cách hiệu
                      quả.
                    </p>
                  </div>
                </div>
                <div className="feature-item">
                  <div className="icon-box">
                    <img
                      src="/assets/images/feature/app-icon2.png"
                      alt="App Icon"
                    />
                  </div>
                  <div className="content-box">
                    <h6>🌟 Quản Lý Hạng Thành Viên</h6>
                    <p>
                      Tùy chỉnh các điều kiện để nâng hạng thành viên và tạo ra
                      sân chơi công khai hấp dẫn. Cung cấp ưu đãi riêng cho từng
                      hạng để kích thích sự tham gia của khách hàng.
                    </p>
                  </div>
                </div>

                <div className="feature-item">
                  <div className="icon-box">
                    <img
                      src="/assets/images/feature/app-icon2.png"
                      alt="App Icon"
                    />
                  </div>
                  <div className="content-box">
                    <h6>📹 Kho Video Hướng Dẫn và Review</h6>
                    <p>
                      Tạo kho video chất lượng với hướng dẫn, đánh giá sản phẩm,
                      và video giải trí để hỗ trợ quá trình bán hàng của bạn
                      ngay trên App để khách hàng có những trải nghiệm thú vị.
                    </p>
                  </div>
                </div>

                <div className="feature-item">
                  <div className="icon-box">
                    <img
                      src="/assets/images/feature/app-icon2.png"
                      alt="App Icon"
                    />
                  </div>
                  <div className="content-box">
                    <h6>🌟 Tích Điểm Thành Viên</h6>
                    <p>
                      Hệ thống tích điểm cho thành viên dựa trên việc giới thiệu
                      người dùng mới và mua hàng, thúc đẩy sự trung thành và
                      tương tác tích cực của khách hàng.
                    </p>
                  </div>
                </div>

                <div className="feature-item">
                  <div className="icon-box">
                    <img
                      src="/assets/images/feature/app-icon2.png"
                      alt="App Icon"
                    />
                  </div>
                  <div className="content-box">
                    <h6>💰 Kiếm Tiền Không Giới Hạn với Affiliate</h6>
                    <p>
                      Tạo cơ hội kiếm tiền bằng cách tham gia vào chương trình
                      liên kết (affiliate program), giúp bạn mở rộng mạng lưới
                      kinh doanh và tăng doanh số bán hàng.
                    </p>
                  </div>
                </div>
                <div className="feature-item">
                  <div className="icon-box">
                    <img
                      src="/assets/images/feature/app-icon2.png"
                      alt="App Icon"
                    />
                  </div>
                  <div className="content-box">
                    <h6>🎁 Voucher</h6>
                    <p>
                      Cung cấp khách hàng các voucher ưu đãi, mã giảm giá và
                      khuyến mãi để tạo sự hấp dẫn và thúc đẩy mua sắm.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="feature-item">
              <div className="icon-box">
                <img
                  src="/assets/images/feature/app-icon2.png"
                  alt="App Icon"
                />
              </div>
              <div className="content-box">
                <h6>🎮 Công Cụ Mini Game và Khảo Sát</h6>
                <p>
                  Tích hợp các công cụ giải trí như mini game để giữ chân khách
                  hàng và tổ chức các cuộc khảo sát để thu thập ý kiến ​​khách
                  hàng và phản hồi cải tiến sản phẩm.
                </p>
              </div>
            </div>

            <div className="feature-item">
              <div className="icon-box">
                <img
                  src="/assets/images/feature/app-icon2.png"
                  alt="App Icon"
                />
              </div>
              <div className="content-box">
                <h6>📊 Quản Lý Khách Hàng</h6>
                <p>
                  Dễ dàng quản lý danh sách khách hàng và tương tác với họ một
                  cách hiệu quả.
                </p>
              </div>
            </div>

            <div className="feature-item">
              <div className="icon-box">
                <img
                  src="/assets/images/feature/app-icon2.png"
                  alt="App Icon"
                />
              </div>
              <div className="content-box">
                <h6>💬 Chat Support Khách Hàng</h6>
                <p>
                  Sử dụng module chat được phát triển linh hoạt để có cái nhìn
                  rõ ràng và tổng quan từ các tương tác của khách hàng với doanh
                  nghiệp.
                </p>
              </div>
            </div>

            <div className="feature-item">
              <div className="icon-box">
                <img
                  src="/assets/images/feature/app-icon2.png"
                  alt="App Icon"
                />
              </div>
              <div className="content-box">
                <h6>📩 Mapping Tin Nhắn ZNS với Sự Kiện Hệ Thống</h6>
                <p>
                  Tối ưu hóa tương tác từ doanh nghiệp tới khách hàng bằng cách
                  mapping tin nhắn ZNS với sự kiện hệ thống.
                </p>
              </div>
            </div>

            <div className="feature-item">
              <div className="icon-box">
                <img
                  src="/assets/images/feature/app-icon2.png"
                  alt="App Icon"
                />
              </div>
              <div className="content-box">
                <h6>📈 Gửi và Quản Lý Đo Lường Tin Nhắn ZNS</h6>
                <p>
                  Theo dõi và quản lý hiệu suất tin nhắn ZNS theo từng chiến
                  dịch tới khách hàng.
                </p>
              </div>
            </div>

            <div className="feature-item">
              <div className="icon-box">
                <img
                  src="/assets/images/feature/app-icon2.png"
                  alt="App Icon"
                />
              </div>
              <div className="content-box">
                <h6>🎨 Cài Đặt Tuỳ Chỉnh Giao Diện App</h6>
                <p>
                  Tùy chỉnh giao diện ứng dụng để phản ánh thương hiệu của bạn
                  một cách hoàn hảo.
                </p>
              </div>
            </div>

            <div className="feature-item">
              <div className="icon-box">
                <img
                  src="/assets/images/feature/app-icon2.png"
                  alt="App Icon"
                />
              </div>
              <div className="content-box">
                <h6>📱 FIT Portal</h6>
                <p>
                  Giải pháp quản lý thông tin nhanh và tiện lợi ngay trên thiết
                  bị di động của bạn. Không cần truy cập website quản trị, FIT
                  Portal giúp bạn tối ưu hóa quá trình làm việc và cải thiện khả
                  năng tương tác với khách hàng một cách dễ dàng.
                </p>
              </div>
            </div>

            <div className="feature-item">
              <div className="icon-box">
                <img
                  src="/assets/images/feature/app-icon2.png"
                  alt="App Icon"
                />
              </div>
              <div className="content-box">
                <h6>🔄 Các Tính Năng Được Cập Nhật Liên Tục</h6>
                <p>
                  Và nhiều tính năng BÍ MẬT khác sẽ được cập nhật liên tục trong
                  phiên bản 2.2 để giúp bạn phát triển doanh nghiệp của mình.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="feature-image">
              <img src="/assets/images/feature/graph2.png" alt="Graph Image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
