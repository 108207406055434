import React from "react";
import Slider from "react-slick";

const dataItemCarosel = [
  {
    name: "Nguyễn Thị Mai Anh",
    position: "Giám Đốc Điều Hành",
    decription:
      "Tôi không thể tin nổi mức độ linh hoạt và tích hợp của FIT Zalo Mini App. Nó đã giúp tôi tiết kiệm thời gian và nâng cao hiệu quả bán hàng.",
  },
  {
    name: "Lê Văn Hoàng",
    position: "Trưởng Phòng Kinh Doanh",
    decription:
      " Tôi không thể đánh giá cao hơn thái độ phục vụ của đội ngũ FIT Zalo Mini App. Họ luôn luôn tạo cảm giác chúng tôi quan trọng và được quan tâm.",
  },
  {
    name: "Vũ Thị Thu Hương",
    position: "Quản lý Quản Trị Nhân Sự",
    decription:
      "Sự kết hợp hoàn hảo giữa tính năng và khả năng tương tác với khách hàng đã làm cho FIT Zalo Mini App trở thành lựa chọn hàng đầu cho doanh nghiệp của tôi.",
  },
  {
    name: "Trần Văn Đức",
    position: "Chuyên Viên Marketing",
    decription:
      "Đội ngũ hỗ trợ của FIT Zalo Mini App thực sự làm việc với tâm huyết. Họ át mọi trở ngại để giúp chúng tôi đạt được mục tiêu kinh doanh.",
  },
  {
    name: "Đỗ Minh Tuấn",
    position: "Chuyên Viên Phát Triển Sản Phẩm",
    decription:
      "Sự tích hợp của FIT Zalo Mini App với hệ sinh thái Zalo là điều tuyệt vời. Nó giúp tôi tiết kiệm thời gian và tối ưu hóa quản lý kinh doanh một cách dễ dàng.",
  },
  {
    name: "Trịnh Thị Ngọc Trâm",
    position: "Giám Sát Sản Xuất",
    decription:
      "Nếu bạn muốn đưa doanh nghiệp của mình lên một tầm cao mới,hãy thử FIT Zalo Mini App. Đó là một cách sáng tạo để tối ưu hóa hoạt động kinh doanh của bạn.",
  },
];
function Caurosel() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    slickPrev: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="testimonial-right-carousel">
      <Slider {...settings}>
        {dataItemCarosel.map((item, index) => {
          return (
            <div key={index} className="testimonial-item">
              <h6>{item.name}</h6>
              <span>{item.position}</span>
              <p>{item.decription}</p>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default Caurosel;
