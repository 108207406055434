import axios from "axios";

const fetchPosts = async (page, perPage, slug) => {
  try {
    let baseUrl = "https://post.fitsoft.vn/wp-json/wp/v2/posts";

    const response = await axios.get(baseUrl, {
      params: {
        page: page,
        per_page: perPage,
        slug,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default fetchPosts;
