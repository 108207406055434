import React from "react";

function Footer() {
  return (
    <footer className="main-footer pt-120 rpt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="widget logo-widget">
              <a target="_blank" href="https://zalo.me/fitsoft">
                <img src="/assets/images/logo.png" alt="Logo" />
              </a>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="widget menu-widget">
              <h6 className="widget-title">Chúng tôi</h6>
              <ul>
                <li>
                  <a target="_blank" href="https://www.facebook.com/fitsoft.vn">
                    Facebook
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://zalo.me/477807272088897358">
                    Zalo OA
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="widget menu-widget">
              <h6 className="widget-title">Dịch vụ</h6>
              <ul>
                <li>
                  <a href="#">Trải nghiệm dễ dàng</a>
                </li>
                <li>
                  <a href="#">Bảo mật cao</a>
                </li>
                <li>
                  <a href="#">Hỗ trợ 24/7</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-sm-6">
            <div className="widget menu-widget">
              <h6 className="widget-title">Xem thử</h6>
              <ul>
                <li>
                  <a
                    target="_blank"
                    href="https://zalo.me/s/1497096725282642967/"
                  >
                    Kho giao diện
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://zalo.me/s/1692106880704818261/"
                  >
                    FIT Portal
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6">
            <div className="widget subscribe-widget">
              <h6 className="widget-title">Tư vấn</h6>
              <form
                action="#"
                method="post"
                name="subscribe-form"
                className="subscribe-form"
              >
                <div className="form-group">
                  <input
                    type="phone"
                    name="phone-address"
                    defaultValue=""
                    className="form-control"
                    placeholder="Nhập sđt của bạn"
                    required=""
                  />
                  <button type="submit" className="subscribe-submit">
                    Gửi
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Copyright Area--> */}
      <div className="copyright-area mt-25">
        <div className="container">
          <div className="copyright-inner">
            <div className="copyright-wrap order-2 order-md-1">
              <p>&copy; Copyright 2022 by FIT Software</p>
            </div>
            {/* <!-- footer menu --> */}
            <ul className="footer-menu order-1 order-md-2">
              <li>
                <a
                  target="_blank"
                  href="https://mini.zalo.me/legal/terms-preview/1497096725282642967"
                >
                  Điều khoản
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://mini.zalo.me/legal/terms-preview/1497096725282642967"
                >
                  Chính sách bảo mật
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
