import DocumentMeta from "react-document-meta";
import Footer from "./component/Footer";
import Header from "./component/Header";
import BlogBanner from "./component/blog/BlogBanner";
import BlogContent from "./component/blog/BlogContent";

function Blog() {
	return (
		<DocumentMeta
			{...{
				title:
					"FIT Zalo Mini App || Giải Pháp Zalo Mini App Cho Doanh Nghiệp Online",
				description:
					"Fit Zalo Mini App - Giải pháp mở ra chiến lược kinh doanh mới. Zalo mini app hỗ trợ bán hàng mà chăm sóc khách hàng ưu việt.  Thiết kế mini zalo app từ Fit Zalo App",
				canonical: "https://miniapp.fitsoft.vn/blog.html",
				meta: {
					charset: "utf-8",
					name: {
						keywords:
							"Zalo Mini App là gì?, Hướng dẫn tạo Zalo Mini App, Lợi ích của Zalo Mini App, Cách tích hợp Zalo Mini App vào website, Cách quảng cáo trên Zalo Mini App, Học lập trình Zalo Mini App, Zalo Mini App vs Zalo Official Account, Zalo Mini App SDK, Chi phí phát triển Zalo Mini App, Ưu và nhược điểm của Zalo Mini App, Cách tối ưu Zalo Mini App cho doanh nghiệp, Zalo Mini App API, Cách thu hút người dùng trên Zalo Mini App, Thành công của doanh nghiệp trên Zalo Mini App, Các ứng dụng nổi tiếng trên Zalo Mini App",
					},
					property: {
						"og:title":
							"FIT Zalo Mini App || Giải Pháp Zalo Mini App Cho Doanh Nghiệp Online",
						"og:description":
							"Fit Zalo Mini App - Giải pháp mở ra chiến lược kinh doanh mới. Zalo mini app hỗ trợ bán hàng mà chăm sóc khách hàng ưu việt.  Thiết kế mini zalo app từ Fit Zalo App",
						"og:url": "https://miniapp.fitsoft.vn/blog.html",
						"og:image":
							"https://file.vttool.online/v1/attachment/image/ff0eb9a3-59b2-44e2-ac61-299478a5461e-logo.png",
						// twitter
						"twitter:domain": "miniapp.fitsoft.vn",
						"twitter:url": "https://miniapp.fitsoft.vn/",
						"twitter:title":
							"FIT Zalo Mini App || Giải Pháp Zalo Mini App Cho Doanh Nghiệp Online",
						"twitter:description":
							"Fit Zalo Mini App - Giải pháp mở ra chiến lỗi. Zalo mini app hên trở bán gì mà chýen cósc khách gì.  Thiết kế mini zalo app là Fit Zalo App",
						"twitter:image":
							"https://file.vttool.online/v1/attachment/image/ff0eb9a3-59b2-44e2-ac61-299478a5461e-logo.png",
					},
				},
			}}>
			<div className="page-wrapper">
				{/*  Main Header */}
				<Header />

				{/*  Page Banner */}
				<BlogBanner />

				{/*  Blog Section  */}
				<BlogContent />

				{/* Main Footer*/}
				<Footer />
			</div>
		</DocumentMeta>
	);
}

export default Blog;
