import React from "react";

const Application = () => {
  return (
    <section className="application-section pt-125 rpt-105 pb-120 rpb-100">
      <div className="power-shape-one">
        <img
          src="/assets/images/shape/powerful-tools-shape1.png"
          alt="Powerful Tools Shape One"
        />
        <div className="power-shape-two">
          <img
            src="/assets/images/shape/powerful-tools-shape2.png"
            alt="Powerful Tools Shape Two"
          />
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="application-content">
              <div className="section-heading mb-40">
                <i className="flaticon-wall-clock"></i>
                <span className="heading-title">Khác biệt để đột phá</span>
                <h2>Trải nghiệm sự khác biệt với FIT Zalo Mini App</h2>
              </div>
              <div className="application-tools mb-10">
                <div className="row">
                  <div className="col-sm-6">
                    <div className="app-tool-item">
                      <div className="app-tool-icon">
                        <i className="flaticon-cone"></i>
                      </div>
                      <div className="app-tool-details">
                        <h6>
                          Trải nghiệm
                          <br />
                          Tuyệt vời
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="app-tool-item">
                      <div className="app-tool-icon">
                        <i className="flaticon-easel"></i>
                      </div>
                      <div className="app-tool-details">
                        <h6>
                          Quản lý
                          <br />
                          Dễ dàng
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                Hãy cùng trải nghiệm sự đột phá với FIT Zalo Mini App, nơi mua
                sắm trở nên tuyệt vời hơn bao giờ hết cho người dùng của bạn. Và
                điều quan trọng hơn, FIT Zalo Mini App đem lại hệ sinh thái tiện
                lợi và đáng trải nghiệm cho doanh nghiệp của bạn.
              </p>
              <a href="https://zalo.me/477807272088897358" className="theme-btn">
                Khám phá thêm
              </a>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="application-image">
              <img
                src="/assets/images/powerful-tools.png"
                alt="Application Image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Application;
