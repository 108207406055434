import React from "react";
import Pagination from "react-bootstrap/Pagination";

const Paginated = ({
  handlePageChange,
  handlePrevPage,
  handleNextPage,
  state,
}) => {
  return (
    <Pagination className="px-4 d-flex justify-content-center">
      <Pagination.Prev onClick={handlePrevPage} />
      {state.data.map((_, index) => {
        const perPage = 4;
        return (
          index < perPage && (
            <Pagination.Item
              onClick={() => handlePageChange(index + 1)}
              key={index}
              active={index + 1 === state.activePage}
              activeLabel=""
            >
              {index + 1}
            </Pagination.Item>
          )
        );
      })}
      <Pagination.Next onClick={handleNextPage} />
    </Pagination>
  );
};
export default Paginated;
