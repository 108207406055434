import React, { useEffect, useState } from "react";
import fetchPosts from "../../api";
import { useLocation } from "react-router-dom";
import Paginated from "./pagination";

const BlogContent = () => {
  const [state, setState] = useState({
    data: [],
    limit: 10,
    activePage: 1,
  });
  const location = useLocation();

  const perPage = 6;

  const fetchData = async () => {
    try {
      const data = await fetchPosts(state.activePage, perPage);
      setState((prev) => ({
        ...prev,
        data: data,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (pageNumber) => {
    setState((prev) => ({ ...prev, activePage: pageNumber }));
    fetchData();
  };
  const handleNextPage = () => {
    setState((prev) => ({ ...prev, activePage: state.activePage + 1 }));
    fetchData();
  };
  const handlePrevPage = () => {
    setState((prev) => ({ ...prev, activePage: state.activePage - 1 }));
    fetchData();
  };

  useEffect(() => {
    fetchData();
    scrollToTop();
  }, [location, state.activePage]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <section className="blog-section pb-90 rpb-70">
      {state.data.length <= 0 && <div className="preloader"></div>}
      <div className="container">
        <div className="row">
          {state.data?.map((item) => {
            var postItem = item?.yoast_head_json;
            var dateTime = postItem?.article_published_time;
            var date = new Date(dateTime);
            var optionDate = {
              year: "numeric",
              month: "long",
              day: "numeric",
            };
            var formatDate = date.toLocaleDateString("vi", optionDate);
            return (
              <div key={item.id} className="col-lg-4 col-md-6">
                <div className="blog-item">
                  <div className="blog-image">
                    <img
                      src={
                        postItem?.og_image?.[0]?.url ||
                        "/assets/images/blog/blog1.jpg"
                      }
                      alt="Blog Image"
                    />
                  </div>
                  <div className="blog-content">
                    <h5>
                      <a href={`blog-details.html/${item?.slug}`}>
                        {postItem?.og_title}
                      </a>
                    </h5>
                    <ul className="title-description">
                      <li>
                        By <a href="blog-details.html">{postItem?.author}</a>
                      </li>
                      <li>{formatDate}</li>
                    </ul>
                    <p>{postItem?.og_description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <Paginated
          handlePageChange={handlePageChange}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
          state={state}
        />
      </div>
    </section>
  );
};

export default BlogContent;
