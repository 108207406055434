import { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { useLocation, useParams } from "react-router-dom";
import fetchPosts from "./api";
import Footer from "./component/Footer";
import Header from "./component/Header";
import BlogDetailSidebar from "./component/blogDetail/BlogDetailSidebar";
import BlogDetailsBanner from "./component/blogDetail/BlogDetailsBanner";

function BlogDetail() {
	const [postDetail, setPostDetail] = useState([]);
	const location = useLocation();
	let { slug } = useParams();
	const page = 1;
	const perPage = 1;

	const [meta, setMeta] = useState({
		// title:
		//   postDetail[0]?.yoast_head_json?.title ||
		//   "FIT Zalo App || Giải Pháp Zalo Mini App Cho Doanh Nghiệp Online",
		// description:
		//   postDetail[0]?.yoast_head_json?.description ||
		//   "Công ty FIT là nhà cung cấp giải pháp công nghệ hàng đầu tại Việt Nam, chuyên nghiệp trong việc phát triển và tối ưu các ứng dụng di động và web app. Một trong những sản phẩm tiêu biểu của chúng tôi là FIT Zalo Mini App - một cầu nối tuyệt vời giữa doanh nghiệp và thị trường e-commerce đầy tiềm năng. Với lượng người dùng Zalo khổng lồ, FIT Zalo Mini App không chỉ giúp doanh nghiệp nhỏ tiếp cận khách hàng một cách nhanh chóng và hiệu quả, mà còn tăng cường sức mạnh cạnh tranh của họ trong thị trường trực tuyến. Được tích hợp đầy đủ các công cụ từ thanh toán đến quản lý đơn hàng và chat trực tiếp, FIT Zalo Mini App là giải pháp toàn diện cho mọi nhu cầu kinh doanh của bạn",
		// canonical: postDetail[0]?.yoast_head_json?.og_url || "",
		meta: {
			charset: "utf-8",
			name: {
				keywords: postDetail[0]?.yoast_head_json?.og_url || "",
			},
		},
	});
	const fetchData = async () => {
		try {
			const data = await fetchPosts(page, perPage, slug);
			setPostDetail(data);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchData();
		scrollToTop();
	}, [location]);

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		if (!!postDetail) {
			setMeta((old) => {
				return {
					...old,
					title: postDetail[0]?.yoast_head_json?.title,
					description: postDetail[0]?.yoast_head_json?.description,
					canonical: postDetail[0]?.yoast_head_json?.og_url,

					meta: {
						charset: "utf-8",
						name: {
							keywords:
								"Zalo Mini App là gì?, Hướng dẫn tạo Zalo Mini App, Lợi ích của Zalo Mini App, Cách tích hợp Zalo Mini App vào website, Cách quảng cáo trên Zalo Mini App, Học lập trình Zalo Mini App, Zalo Mini App vs Zalo Official Account, Zalo Mini App SDK, Chi phí phát triển Zalo Mini App, Ưu và nhược điểm của Zalo Mini App, Cách tối ưu Zalo Mini App cho doanh nghiệp, Zalo Mini App API, Cách thu hút người dùng trên Zalo Mini App, Thành công của doanh nghiệp trên Zalo Mini App, Các ứng dụng nổi tiếng trên Zalo Mini App",
						},
						property: {
							"og:title": postDetail[0]?.yoast_head_json?.og_title,
							"og:description": postDetail[0]?.yoast_head_json?.og_description,
							"og:url": postDetail[0]?.yoast_head_json?.og_url,
							"og:image": postDetail[0]?.yoast_head_json?.og_image[0]?.url,
							// twitter
							"twitter:domain": "miniapp.fitsoft.vn",
							"twitter:url": "https://miniapp.fitsoft.vn/",
							"twitter:title": postDetail[0]?.yoast_head_json?.og_title,
							"twitter:description":
								postDetail[0]?.yoast_head_json?.og_description,
							"twitter:image": postDetail[0]?.yoast_head_json?.og_image[0]?.url,
						},
					},
				};
			});
		}
	}, [postDetail]);

	return (
		<DocumentMeta {...meta}>
			<div className="page-wrapper">
				{/* Main Header  */}
				<Header />

				{/* Page Banner*/}
				<BlogDetailsBanner />

				{/* <!-- START Blog Details --> */}
				<section className="blog-details pb-120 rpb-100">
					<div className="container">
						<div className="row">
							<div className="col-lg-8">
								{/* <!-- Blog Details Content --> */}
								<div className="blog-details-content">
									{postDetail.length <= 0 && <div className="preloader"></div>}
									{/* <!-- Blog Details Content --> */}
									{postDetail.map((itemDetail) => {
										var postItem = itemDetail?.yoast_head_json;
										var dateTime = postItem?.article_published_time;
										var date = new Date(dateTime);
										var optionDate = {
											year: "numeric",
											month: "long",
											day: "numeric",
										};
										var formatDate = date.toLocaleDateString("vi", optionDate);
										return (
											<div key={itemDetail.id} className="blog-details-text">
												<h3>{itemDetail.title.rendered}</h3>
												<div
													dangerouslySetInnerHTML={{
														__html: itemDetail.content.rendered,
													}}></div>
												<p>{formatDate}</p>
												<p>By {postItem.author}</p>
											</div>
										);
									})}
									<div className="comment-form">
										<h5>Để lại một câu trả lời</h5>
										<form action="#">
											<div className="row">
												<div className="col-md-6">
													<input
														type="text"
														className="form-control custom-input"
														id="name"
														name="name"
														defaultValue=""
														placeholder="Tên của bạn"
														required=""
													/>
												</div>
												<div className="col-md-6">
													<input
														type="email"
														className="form-control custom-input"
														id="email"
														name="email"
														defaultValue=""
														placeholder="Email của bạn"
														required=""
													/>
												</div>
												<div className="col-12">
													<input
														type="text"
														className="form-control custom-input"
														id="subject"
														name="subject"
														defaultValue=""
														placeholder="Vấn đề của bạn"
													/>
												</div>
												<div className="col-12">
													<textarea
														className="form-control custom-textarea"
														id="message:"
														name="message:"
														rows="8"
														placeholder="Tin nhắn"
														required=""></textarea>
												</div>
												<div className="col-12">
													<button
														type="submit"
														className="theme-btn submit-btn">
														Tin nhắn
													</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div className="col-lg-4">
								{/* <!-- Blog Sidebar --> */}
								<BlogDetailSidebar />
							</div>
						</div>
					</div>
				</section>

				{/*  Main Footer  */}
				<Footer />
			</div>
		</DocumentMeta>
	);
}

export default BlogDetail;
