import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function TikTokAuth() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  //   const history = useHistory();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const code = query.get("code");
    const state = query.get("state");

    // Check for error
    const errorParam = query.get("error");
    if (errorParam) {
      setError(errorParam);
      setLoading(false);
      return;
    }

    // Verify state parameter if needed
    // const csrfState = localStorage.getItem('csrfState');
    // if (state !== csrfState) {
    //   setError('CSRF Token mismatch');
    //   setLoading(false);
    //   return;
    // }

    // Exchange authorization code for access token
    if (code) {
      // Call your backend to exchange code for token
      fetch("https://api.shpee.buzz/v1/auth/tiktok/callback", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code, state }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "Failed to exchange authorization code for access token"
            );
          }
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
        });
    } else {
      setError("Authorization code not found");
      setLoading(false);
    }
  }, [location.search]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // If successful, redirect to home page or any other page
  window.location.href = "/"
  return null; // or any other UI component
}

export default TikTokAuth;
