import React from "react";

const Service = () => {
  return (
    <section className="service-section pb-90 rpb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-8">
            <div className="section-heading text-center mb-80 rmb-40">
              <span className="heading-title">FIT ZALO APP</span>
              <h2>Cung cấp giải pháp Zalo Mini App</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 p-0">
            <div className="service-item">
              <div className="service-icon">
                <img
                  src="/assets/images/service/icon1.png"
                  alt="Service Icon"
                />
              </div>
              <div className="service-content">
                <h5>Tận hưởng ngay trải nghiệm miễn phí với Zalo Mini App!</h5>
                <p>
                  Chúng tôi hiểu rằng bạn cần thời gian để cảm nhận sự hiệu quả
                  của ứng dụng này. Vì vậy, chúng tôi đang tặng bạn một cơ hội
                  trải nghiệm miễn phí không giới hạn. Chỉ khi bạn thực sự cảm
                  thấy hài lòng về sự hiệu quả, bạn mới cần đưa ra quyết định
                  chính xác.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 p-0">
            <div className="service-item service-active">
              <div className="service-icon">
                <img
                  src="/assets/images/service/icon2.png"
                  alt="Service Icon"
                />
              </div>
              <div className="service-content">
                <h5>Dễ dàng tùy chỉnh</h5>
                <p>
                  Sự đa dạng và tính linh hoạt của Kho giao diện FIT Zalo App
                  chắc chắn sẽ đáp ứng được nhu cầu của nhiều ngành nghề khác
                  nhau. Chúng tôi cam kết sản phẩm của mình có thể được tuỳ biến
                  một cách dễ dàng để phù hợp với mọi yêu cầu của bạn.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 p-0">
            <div className="service-item">
              <div className="service-icon">
                <img
                  src="/assets/images/service/icon3.png"
                  alt="Service Icon"
                />
              </div>
              <div className="service-content">
                <h5>Trọn đời sử dụng và update miễn phí</h5>
                <p>
                  Tại FIT Zalo App, chúng tôi hiểu rằng bạn đang tìm kiếm một
                  giải pháp không chỉ phục vụ cho nhu cầu ngày hôm nay mà còn
                  đồng hành với bạn suốt cả cuộc đời. Chúng tôi tự hào cam kết
                  rằng sản phẩm của chúng tôi sẽ luôn được cập nhật miễn phí
                  vĩnh viễn.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Service;
