import { useEffect } from "react";
import DocumentMeta from "react-document-meta";
import { useLocation } from "react-router-dom";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Application from "./component/home/Application";
import BlogHome from "./component/home/BlogHome";
import DecoverTools from "./component/home/DecoverTools";
import Feature from "./component/home/Feature";
import Funfact from "./component/home/Funfact";
import Hero from "./component/home/Hero";
import Pricing from "./component/home/Pricing";
import Service from "./component/home/Service";
import Team from "./component/home/Team";
import Testimonial from "./component/home/Testimonial";

function App() {
	const location = useLocation();

	useEffect(() => {
		scrollToTop();
	}, [location]);
	const scrollToTop = () => {
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		let hash = window.location?.hash.slice(1);
		let element = document.getElementById(hash);

		if (!element) return;

		element.scrollIntoView();
	}, []);

	return (
		<DocumentMeta
			{...{
				title:
					"FIT Zalo Mini App || Giải Pháp Zalo Mini App Cho Doanh Nghiệp Online",
				description:
					"Fit Zalo Mini App - Giải pháp mở ra chiến lược kinh doanh mới. Zalo mini app hỗ trợ bán hàng mà chăm sóc khách hàng ưu việt.  Thiết kế mini zalo app từ Fit Zalo App",
				canonical: "https://miniapp.fitsoft.vn/",
				meta: {
					charset: "utf-8",
					name: {
						keywords:
							"Zalo Mini App là gì?, Hướng dẫn tạo Zalo Mini App, Lợi ích của Zalo Mini App, Cách tích hợp Zalo Mini App vào website, Cách quảng cáo trên Zalo Mini App, Học lập trình Zalo Mini App, Zalo Mini App vs Zalo Official Account, Zalo Mini App SDK, Chi phí phát triển Zalo Mini App, Ưu và nhược điểm của Zalo Mini App, Cách tối ưu Zalo Mini App cho doanh nghiệp, Zalo Mini App API, Cách thu hút người dùng trên Zalo Mini App, Thành công của doanh nghiệp trên Zalo Mini App, Các ứng dụng nổi tiếng trên Zalo Mini App",
					},
					property: {
						"og:title":
							"FIT Zalo Mini App || Giải Pháp Zalo Mini App Cho Doanh Nghiệp Online",
						"og:description":
							"Fit Zalo Mini App - Giải pháp mở ra chiến lược kinh doanh mới. Zalo mini app hỗ trợ bán hàng mà chăm sóc khách hàng ưu việt.  Thiết kế mini zalo app từ Fit Zalo App",
						"og:url": "https://miniapp.fitsoft.vn/",
						"og:image":
							"https://file.vttool.online/v1/attachment/image/ff0eb9a3-59b2-44e2-ac61-299478a5461e-logo.png",
						// twitter
						"twitter:domain": "miniapp.fitsoft.vn",
						"twitter:url": "https://miniapp.fitsoft.vn/",
						"twitter:title":
							"FIT Zalo Mini App || Giải Pháp Zalo Mini App Cho Doanh Nghiệp Online",
						"twitter:description":
							"Fit Zalo Mini App - Giải pháp mở ra chiến lỗi. Zalo mini app hên trở bán gì mà chýen cósc khách gì.  Thiết kế mini zalo app là Fit Zalo App",
						"twitter:image":
							"https://file.vttool.online/v1/attachment/image/ff0eb9a3-59b2-44e2-ac61-299478a5461e-logo.png",
					},
				},
			}}>
			<div className="page-wrapper">
				{/* Header */}
				<Header />

				{/* Hero */}
				<Hero />

				<div className="partner-and-service">
					<div className="partner-shape">
						<img
							src="/assets/images/shape/partner-shape.png"
							alt="Partner Shape"
						/>
					</div>

					{/* <div className="partner-section py-120 rpy-100">
          <div className="container">
            <div className="partner-wrap">
              <div className="partner-image">
                <a href="#">
                  <img
                    src="/assets/images/partners/partner1.png"
                    alt="Partner Logo"
                  />
                </a>
              </div>
              <div className="partner-image">
                <a href="#">
                  <img
                    src="/assets/images/partners/partner2.png"
                    alt="Partner Logo"
                  />
                </a>
              </div>
              <div className="partner-image">
                <a href="#">
                  <img
                    src="/assets/images/partners/partner3.png"
                    alt="Partner Logo"
                  />
                </a>
              </div>
              <div className="partner-image">
                <a href="#">
                  <img
                    src="/assets/images/partners/partner4.png"
                    alt="Partner Logo"
                  />
                </a>
              </div>
              <div className="partner-image">
                <a href="#">
                  <img
                    src="/assets/images/partners/partner2.png"
                    alt="Partner Logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div> */}
					{/* <!-- END Partner Section --> */}

					<div className="service-shape">
						<img
							src="/assets/images/shape/service-shape.png"
							alt="Service Shape"
						/>
					</div>

					{/* Service */}
					<Service />
				</div>

				<div className="feature-and-funfact">
					<div className="feature-shape-one">
						<img
							src="/assets/images/shape/feature-shape1.png"
							alt="Graph Shape One"
						/>
					</div>
					<div className="feature-shape-two">
						<img
							src="/assets/images/shape/feature-shape2.png"
							alt="Graph Shape Two"
						/>
						<div className="feature-shape-three">
							<img
								src="/assets/images/shape/feature-shape3.png"
								alt="Graph Shape Three"
							/>
						</div>
					</div>
					{/* <!-- START Feature Section --> */}
					<Feature />
					{/* <!-- END Feature Section --> */}

					{/* <!-- START Fun Fact Section --> */}
					<Funfact />
					{/* <!-- END Fun Fact Section --> */}
				</div>
				{/* Discover Tools*/}
				<DecoverTools />

				{/* Application  */}
				<Application />

				{/*  Pricing */}
				<Pricing />

				{/*  Testimonial */}
				<Testimonial />

				{/* Team  */}
				<Team />

				{/*  Video  */}
				<section className="video-section">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-lg-8">
								<div
									className="video-wrap"
									style={{
										background: `url(https://i.ytimg.com/an_webp/28KY8KTJEJs/mqdefault_6s.webp?du=3000&sqp=CO_kw6gG&rs=AOn4CLBLsnFpK6YUzFDGUuhmsD0gURgHQA)`,
										backgroundSize: "cover",
									}}>
									<div className="video-content text-center">
										<a
											href="https://www.youtube.com/watch?v=28KY8KTJEJs"
											className="mfp-iframe video-play">
											<i className="flaticon-play"></i>
										</a>
										<h5>TRUYỀN THÔNG & KINH DOANH</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				{/*  App Screen */}
				{/* <section className="app-screen-section pt-130 rpt-110 pb-95 rpb-90">
        <div className="app-screen-shape">
          <img
            src="/assets/images/shape/app-screen-shape.png"
            alt="App Screen Shape Image"
          />
        </div>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-7">
              <div className="section-heading text-center mb-60 rmb-40">
                <span className="heading-title">Trải nghiệm</span>
                <h2>Giao diện chuyên nghiệp</h2>
              </div>
            </div>
          </div>
          <div className="app-screen-wrap">
            <div className="mobile-frame">
              <img
                src="/assets/images/app-screen/mobile-frame.png"
                alt="Mobile Frame Image"
              />
            </div>
            <div className="app-screen-carousel">
              <div className="app-screen">
                <img
                  src="/assets/images/app-screen/screen1.jpg"
                  alt="App Screen Image"
                />
              </div>
              <div className="app-screen">
                <img
                  src="/assets/images/app-screen/screen2.jpg"
                  alt="App Screen Image"
                />
              </div>
              <div className="app-screen">
                <img
                  src="/assets/images/app-screen/screen3.jpg"
                  alt="App Screen Image"
                />
              </div>
              <div className="app-screen">
                <img
                  src="/assets/images/app-screen/screen4.jpg"
                  alt="App Screen Image"
                />
              </div>
              <div className="app-screen">
                <img
                  src="/assets/images/app-screen/screen5.jpg"
                  alt="App Screen Image"
                />
              </div>
              <div className="app-screen">
                <img
                  src="/assets/images/app-screen/screen2.jpg"
                  alt="App Screen Image"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}

				{/*  Blog  */}
				<BlogHome />

				{/*  Main Footer */}
				<Footer />
			</div>
		</DocumentMeta>
	);
}

export default App;
