import React from "react";

const Funfact = () => {
  return (
    <section className="fun-fact-section pb-120 rpb-100">
      <div className="container">
        <div className="fun-fact-wrap">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="fact-item">
                <h2>5X</h2>
                <h5>Tiếp cận Khách hàng nhanh hơn</h5>
                <p>
                  Với Fit Zalo App, bạn có thể tích hợp Mini App của mình trực
                  tiếp vào Zalo và tiếp cận hàng triệu người dùng trên mạng xã
                  hội một cách nhanh chóng. Điều này giúp bạn xây dựng thương
                  hiệu, tạo lòng tin và tăng doanh số bán hàng.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="fact-item">
                <h2>⬇80%</h2>
                <h5>Tiết kiệm hơn</h5>
                <p>
                  Với Fit Zalo App, bạn không cần phải đầu tư lớn cho việc phát
                  triển các ứng dụng di động riêng biệt. Chúng tôi đã chuẩn bị
                  sẵn giao diện cho nhiều ngành nghề khác nhau để bạn có thể lựa
                  chọn và tùy chỉnh nhanh chóng.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="fact-item">
                <h2>75M+</h2>
                <h5>Đứng trên vai người khổng lồ</h5>
                <p>
                  Tận dụng hệ sinh thái và người dùng Zalo có thể giúp doanh
                  nghiệp tối ưu hóa tiếp cận, tạo ra trải nghiệm khách hàng tốt
                  hơn và tăng doanh số bán hàng. Điều này là một cơ hội đầy tiềm
                  năng để phát triển và thành công trong môi trường kinh doanh
                  trực tuyến ngày nay.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Funfact;
