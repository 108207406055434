import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import fetchPosts from "../../api";

const BlogHome = () => {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const page = 1;
  const perPage = 2;
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const data = await fetchPosts(page, perPage);
      setPosts(data);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <section className="blog-section pt-120 rpt-100 pb-90 rpb-70">
      {posts.length <= 0 && <div className="preloader"></div>}
      <div className="blog-shape-one">
        <img src="/assets/images/shape/blog-shape1.png" alt="Blog Shape One" />
        <div className="blog-shape-two">
          <img
            src="/assets/images/shape/blog-shape2.png"
            alt="Blog Shape Two"
          />
        </div>
      </div>
      <div
        className="container"
        id="blog-section"
        onClick={() => {
          navigate("/blog.html");
        }}
      >
        <div className="row">
          <div className="col-lg-4">
            <div className="section-heading mb-20">
              <span className="heading-title">Từ các bài viết trên blog</span>
              <h2>Tin tức & Bài viết</h2>
            </div>
            <p className="rmb-40">
              Trong loạt bài viết này, chúng ta sẽ khám phá và tìm hiểu về Zalo
              Mini App - một nền tảng phát triển ứng dụng nhỏ gọn, linh hoạt và
              mạnh mẽ của Zalo. Chúng ta sẽ khám phá các khía cạnh quan trọng
              của Zalo Mini App,bao gồm cách xây dựng và triển khai ứng dụng,
              tương tác với người dùng,tích hợp các chức năng và dịch vụ của
              Zalo và nhiều hơn nữa.
            </p>
          </div>

          {posts?.map((item) => {
            var postItem = item.yoast_head_json;
            var dateTime = postItem.article_published_time;
            var date = new Date(dateTime);
            var optionDate = {
              year: "numeric",
              month: "long",
              day: "numeric",
            };
            var formatDate = date.toLocaleDateString("vi", optionDate);
            return (
              <div key={item.id} className="col-lg-4 col-md-6">
                <div className="blog-item">
                  <div className="blog-image">
                    <img
                      src={
                        postItem?.og_image[0].url ||
                        "/assets/images/blog/blog1.jpg"
                      }
                      alt="Blog Image"
                    />
                  </div>
                  <div className="blog-content">
                    <h5>
                      <a href={`blog-details.html/${item?.slug}`}>
                        {postItem.og_title}
                      </a>
                    </h5>
                    <ul className="title-description">
                      <li>
                        By <a href="blog-details.html">{postItem.author}</a>
                      </li>
                      <li>{formatDate}</li>
                    </ul>
                    <p>{postItem.og_description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BlogHome;
