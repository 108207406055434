import React from "react";
import Caurosel from "./Caurosel";

const Testimonial = () => {
	return (
		<div>
			{" "}
			<section
				className="testimonial-section pt-90 rpt-70 pb-100 rpb-80"
				id="feeback">
				<div className="testimonial-shape">
					<img
						src="/assets/images/shape/testimonial-shape.png"
						alt="Testimonial Shape"
					/>
				</div>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6">
							<div className="testimonial-left-content">
								<div className="section-heading mb-20">
									<span className="heading-title">Giá trị để lại</span>
									<h2>Khách hàng của chúng tôi nói gì</h2>
								</div>
								<h6>Sự Thay Đổi Lớn Cho Doanh Nghiệp Của Tôi!!</h6>
								<ul className="stars">
									<li>
										<i className="fas fa-star"></i>
									</li>
									<li>
										<i className="fas fa-star"></i>
									</li>
									<li>
										<i className="fas fa-star"></i>
									</li>
									<li>
										<i className="fas fa-star"></i>
									</li>
									<li>
										<i className="fas fa-star"></i>
									</li>
								</ul>
								<p>
									FIT Zalo Mini App đã thay đổi hoàn toàn cách tôi kinh doanh.
									Tích hợp Zalo vào quản lý kinh doanh là một điều tuyệt vời!
								</p>
								<p>
									FIT Zalo Mini App đã giúp tôi thấy tự tin hơn trong việc kinh
									doanh trực tuyến. Đây là một sản phẩm thực sự xuất sắc!
								</p>
								<a
									target="_blank"
									href="https://zalo.me/477807272088897358"
									className="theme-btn">
									Khám phá thêm
								</a>
							</div>
						</div>
						<div className="col-lg-6">
							<Caurosel />
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Testimonial;
