import React from "react";

const Pricing = () => {
  return (
    <section className="pricing-section pt-115 rpt-95" id="pricing-section">
      <div className="pricing-shape">
        <img src="/assets/images/shape/pricing-shape.png" alt="Pricing Shape" />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-7">
            <div className="section-heading text-center mb-50 rmb-40">
              <span className="heading-title">Bảng giá</span>
              <h2>Các gói giá phù hợp với nhu cầu của bạn</h2>
            </div>
          </div>
        </div>

        {/* <!-- Pricing Tab --> */}
        {/* <!-- <ul className="nav nav-tabs" id="pricingTab" role="tablist">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active"
                id="monthly-tab"
                data-toggle="tab"
                href="#monthly"
                role="tab"
                aria-controls="monthly"
                aria-selected="true"
                ><span>Monthly</span></a
              >
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="yearly-tab"
                data-toggle="tab"
                href="#yearly"
                role="tab"
                aria-controls="yearly"
                aria-selected="false"
                ><span>Yearly</span></a
              >
            </li>
          </ul> --> */}
        {/* <!-- Pricing Tab Content --> */}
        <div className="tab-content" id="pricingTabContent">
          <div
            className="tab-pane fade show active"
            id="monthly"
            role="tabpanel"
            aria-labelledby="monthly-tab"
          >
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-6">
                <div className="pricing-block text-center">
                  <div className="price-box">
                    <span>Gói Cơ bản</span>
                    <h3 className="price">5.000.000</h3>
                  </div>
                  <ul className="features" style={{ minHeight: "400px" }}>
                    <li>Danh mục/Sản phẩm</li>
                    <li>Giỏ hàng/Mua hàng</li>
                    <li>Quản lý đơn hàng</li>
                    <li>Tìm kiếm sản phẩm</li>
                    <li>Liên kết Zalo OA</li>
                    <li>Gửi tin nhắn ZNS</li>
                    <li>Chia sẻ sản phẩm bằng QR, đường link</li>
                    <li>Voucher(giảm giá, Tích Point)</li>
                    <li>Tin tức</li>
                    
                    <li
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                      Ưu đãi hạng thành viên
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                     Mini Game
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                   Affiliate nhận Point
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                     Khuyến mãi
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                     Thiết kế giao diện theo yêu cầu
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                     Thiết kế chức năng theo yêu cầu
                    </li>
                  </ul>
                  <div className="price-btn">
                    <a
                      target="_blank"
                      href="https://zalo.me/fitsoft"
                      className="theme-btn"
                    >
                      Tìm hiểu thêm
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="pricing-block text-center">
                  <div className="price-box">
                    <span>Gói Tiêu chuẩn</span>
                    <h3 className="price">7.000.000</h3>
                  </div>
                  <ul className="features" style={{ minHeight: "400px" }}>
                  <li>Danh mục/Sản phẩm</li>
                    <li>Giỏ hàng/Mua hàng</li>
                    <li>Quản lý đơn hàng</li>
                    <li>Tìm kiếm sản phẩm</li>
                    <li>Liên kết Zalo OA</li>
                    <li>Gửi tin nhắn ZNS</li>
                    <li>Chia sẻ sản phẩm bằng QR, đường link</li>
                    <li>Voucher(giảm giá, Tích Point)</li>
                    <li>Tin tức</li>
                    <li>Ưu đãi hạng thành viên</li>
                    <li>Mini Game</li>
                  
                    <li
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                   Affiliate nhận Point
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                     Khuyến mãi
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                     Thiết kế giao diện theo yêu cầu
                    </li>
                    <li
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                     Thiết kế chức năng theo yêu cầu
                    </li>
                  </ul>
                  <div className="price-btn">
                    <a
                      target="_blank"
                      href="https://zalo.me/fitsoft"
                      className="theme-btn"
                    >
                      Tìm hiểu thêm
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="pricing-block text-center">
                  <div className="price-box">
                    <span>Gói Nâng cao</span>
                    <h3 className="price">12.000.000</h3>
                  </div>
                  <ul className="features" style={{ minHeight: "400px" }}>
                  <li>Danh mục/Sản phẩm</li>                
                  <li>Giỏ hàng/Mua hàng</li>
                    <li>Quản lý đơn hàng</li>
                    <li>Tìm kiếm sản phẩm</li>
                    <li>Liên kết Zalo OA</li>
                    <li>Gửi tin nhắn ZNS</li>
                    <li>Chia sẻ sản phẩm bằng QR, đường link</li>
                    <li>Voucher(giảm giá, Tích Point)</li>
                    <li>Tin tức</li>
                    <li>Ưu đãi hạng thành viên</li>
                    <li>Mini Game</li>
                    <li>Affiliate nhận Point</li>
                    <li>Khuyến mãi</li>
                    <li>Thiết kế giao diện theo yêu cầu</li>
                    <li
                      style={{
                        textDecoration: "line-through",
                      }}
                    >
                     Thiết kế chức năng theo yêu cầu
                    </li>
                  </ul>
                  <div className="price-btn">
                    <a
                      target="_blank"
                      href="https://zalo.me/fitsoft"
                      className="theme-btn"
                    >
                      Tìm hiểu thêm
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="pricing-block text-center">
                  <div className="price-box">
                    <span>Gói Tùy chỉnh</span>
                    <h3 className="price">Liên hệ</h3>
                  </div>
                  <ul className="features" style={{ minHeight: "400px" }}>
                    <li>Danh mục/Sản phẩm</li>                
                    <li>Giỏ hàng/Mua hàng</li>
                    <li>Quản lý đơn hàng</li>
                    <li>Tìm kiếm sản phẩm</li>
                    <li>Liên kết Zalo OA</li>
                    <li>Gửi tin nhắn ZNS</li>
                    <li>Chia sẻ sản phẩm bằng QR, đường link</li>
                    <li>Voucher(giảm giá, Tích Point)</li>
                    <li>Tin tức</li>
                    <li>Ưu đãi hạng thành viên</li>
                    <li>Mini Game</li>
                    <li>Affiliate nhận Point</li>
                    <li>Khuyến mãi</li>
                    <li>Thiết kế giao diện theo yêu cầu</li>
                    <li>Thiết kế chức năng theo yêu cầu</li>
                  </ul>
                  <div className="price-btn">
                    <a
                      target="_blank"
                      href="https://zalo.me/fitsoft"
                      className="theme-btn"
                    >
                      Tìm hiểu thêm
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="yearly"
            role="tabpanel"
            aria-labelledby="yearly-tab"
          >
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6">
                <div className="pricing-block text-center">
                  <div className="price-box">
                    <span>Kế hoạch cơ bản</span>
                    <h3 className="price">6.794.200 VND</h3>
                  </div>
                  <ul className="features">
                    <li>Tính năng bổ sung</li>
                    <li>Hỗ trợ không giới hạn</li>
                    <li>Tùy chọn nâng cấp</li>
                    <li>Toàn quyền truy cập</li>
                  </ul>
                  <div className="price-btn">
                    <a
                      target="_blank"
                      href="https://zalo.me/fitsoft"
                      className="theme-btn"
                    >
                      Tìm hiểu thêm
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-block text-center">
                  <div className="price-box">
                    <span>Kế hoạch nâng cao</span>
                    <h3 className="price">11.647.200 VND</h3>
                  </div>
                  <ul className="features">
                    <li>Tính năng bổ sung</li>
                    <li>Hỗ trợ không giới hạn</li>
                    <li>Tùy chọn nâng cấp</li>
                    <li>Toàn quyền truy cập</li>
                  </ul>
                  <div className="price-btn">
                    <a
                      target="_blank"
                      href="https://zalo.me/fitsoft"
                      className="theme-btn"
                    >
                      Tìm hiểu thêm
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="pricing-block text-center">
                  <div className="price-box">
                    <span>Kế hoạch chuyên gia</span>
                    <h3 className="price">14.073.700 VND</h3>
                  </div>
                  <ul className="features">
                    <li>Tính năng bổ sung</li>
                    <li>Hỗ trợ không giới hạn</li>
                    <li>Tùy chọn nâng cấp</li>
                    <li>Toàn quyền truy cập</li>
                  </ul>
                  <div className="price-btn">
                    <a
                      target="_blank"
                      href="https://zalo.me/fitsoft"
                      className="theme-btn"
                    >
                      Tìm hiểu thêm
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
