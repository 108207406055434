import React from "react";

const Team = () => {
  return (
    <section className="team-section pt-115 rpt-95 pb-70 rpb-50">
      <div className="team-shape">
        <img src="/assets/images/shape/team-shape.png" alt="Team Shape" />
      </div>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-md-7">
            <div className="section-heading text-center mb-50 rmb-40">
              <span className="heading-title">Dự án</span>
              <h2>Các dự án nổi bật</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="team-member">
              <div className="member-image">
                <img
                  src="https://file.vttool.online/v1/attachment/image/71186424-a343-4037-8d0a-f6440056613f-banner.jpg"
                  alt="Team Member Image"
                />
              </div>
              <div className="member-content">
                <h6>Thẩm mỹ Hoàng Tuấn</h6>
                <span>Đón niềm tin, trao sự hoàn mỹ</span>
              </div>
              <ul className="social-style">
                <li>
                  <a href="https://zalo.me/s/3285433156966257954/">
                    <i className="fa fa-mobile"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/NhaKhoa.HoangTuan/"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://thammyhoangtuan.vn/">
                    <i className="fab fa-internet-explorer"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="team-member">
              <div className="member-image">
                <img
                  src="https://file.vttool.online/v1/attachment/image/558bb2b0-f03b-49b8-b089-943379a26cf3-van-anh-spa.jpeg"
                  alt="Team Member Image"
                />
              </div>
              <div className="member-content">
                <h6>Vân Anh Spa</h6>
                <span>Nơi gửi gắm niềm tin của phái đẹp!</span>
              </div>
              <ul className="social-style">
                <li>
                  <a
                    target="_blank"
                    href="https://zalo.me/s/1488266689152491949/"
                  >
                    <i className="fa fa-mobile"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/ThamMyHoangTuan"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="team-member">
              <div className="member-image">
                <img
                  src="https://file.vttool.online/v1/attachment/image/dd13f54f-9235-4cac-8c0c-4345ac737747-logo.jpg"
                  alt="Team Member Image"
                />
              </div>
              <div className="member-content">
                <h6>MundoPet</h6>
                <span>Siêu thị thức ăn phụ kiện thú cưng</span>
              </div>
              <ul className="social-style">
                <li>
                  <a
                    target="_blank"
                    href="https://zalo.me/s/4129334447940435428/"
                  >
                    <i className="fa fa-mobile"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/MundoPet.vn/"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="team-member">
              <div className="member-image">
                <img
                  src="https://file.vttool.online/v1/attachment/image/d0136b45-c5f5-4549-9369-d02211428ea8-logo.jpg"
                  alt="Team Member Image"
                />
              </div>
              <div className="member-content">
                <h6>Dr.Halee Store</h6>
                <span>Thanh xuân là hạnh phúc</span>
              </div>
              <ul className="social-style">
                <li>
                  <a
                    target="_blank"
                    href="https://zalo.me/s/356926575658610298/"
                  >
                    <i className="fa fa-mobile"></i>
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/drhaleestore/"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://drhaleeofficial.vn/">
                    <i className="fab fa-internet-explorer"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
