import React from "react";
import { Link } from "react-router-dom";

const BlogBanner = () => {
  return (
    <section className="page-banner">
      <div className="hero-circle-shape">
        <img
          src="/assets/images/shape/hero-circle-shape.png"
          alt="Hero Circle Shape"
        />
      </div>
      <div className="hero-moon-shape">
        <img
          src="/assets/images/shape/hero-moon-shape.png"
          alt="Hero Moon Shape"
        />
      </div>
      <div className="hero-right-shape">
        <img
          src="/assets/images/shape/hero-right-shape.png"
          alt="Hero Right Shape"
        />
      </div>
      <div className="container">
        <div className="banner-inner">
          <h2>FIT Zalo Mini App</h2>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/index.html">Trang chủ</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Blog
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default BlogBanner;
