import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Header() {
  const location = useLocation();

  const isHomePage = location.pathname === "/";

  return (
    <header className="main-header bg-gradient">
      {/* <!-- Header-Upper --> */}
      <div className="header-upper">
        <div className="container clearfix">
          <div className="header-inner d-lg-flex align-items-center">
            <div className="logo-outer d-flex align-items-center">
              <div className="logo">
                <a href="/">
                  <img src="/assets/images/logo.png" alt="Logo" />
                </a>
              </div>
            </div>

            <div className="nav-outer clearfix ml-lg-auto">
              {/* <!-- Main Menu --> */}
              <nav className="main-menu navbar-expand-lg">
                <div className="navbar-header clearfix">
                  {/* <!-- Toggle Button --> */}
                  <button
                    type="button"
                    className="navbar-toggle"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                  >
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                </div>

                <div className="navbar-collapse collapse clearfix">
                  <ul className="navigation clearfix">
                    <li className="current-menu-item">
                      <a href="/">Trang chủ</a>
                    </li>
                    <li>
                      <a href={isHomePage ? "#features" : "/#features"}>v2.1</a>
                    </li>
                    <li>
                      <a
                        href={
                          isHomePage ? "#pricing-section" : "/#pricing-section"
                        }
                      >
                        Bảng giá
                      </a>
                    </li>
                    <li>
                      <Link to="/blog.html">Bài viết</Link>
                    </li>
                    <li>
                      <a href={isHomePage ? "#feeback" : "/#feeback"}>
                        Về chúng tôi
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href="https://zalo.me/477807272088897358">
                        Liên hệ
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
              {/* <!-- Main Menu END --> */}
            </div>

            <div className="menu-btn">
              <a
                target="_blank"
                href="https://zalo.me/app/link/zapps/1497096725282642967"
                className="theme-btn"
              >
                Dùng thử ngay
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- END Header Upper --> */}
    </header>
  );
}

export default Header;
