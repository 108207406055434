import React from "react";

const Hero = () => {
  return (
    <section className="hero-section">
      <div className="hero-circle-shape">
        <img
          src="/assets/images/shape/hero-circle-shape.png"
          alt="Hero Circle Shape"
        />
      </div>
      <div className="hero-dot-shape">
        <img
          src="/assets/images/shape/hero-dot-shape.png"
          alt="Hero Dot Shape"
        />
      </div>
      <div className="hero-moon-shape">
        <img
          src="/assets/images/shape/hero-moon-shape.png"
          alt="Hero Moon Shape"
        />
      </div>
      <div className="hero-right-shape">
        <img
          src="/assets/images/shape/hero-right-shape.png"
          alt="Hero Right Shape"
        />
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="hero-content">
              <h2>Giải Pháp Zalo Mini App Cho Doanh Nghiệp Online</h2>
              <p>
                Tham gia ngay cùng Fit Zalo App và chúng tôi sẽ hỗ trợ bạn trong
                hành trình thương mại điện tử đầy tiềm năng và phát triển!
              </p>
              <a href="https://zalo.me/477807272088897358" className="theme-btn">
                Khám phá thêm
              </a>
            </div>
          </div>
          <div className="col-lg-7">
            <div className="hero-mobile-application">
              <img
                src="/assets/images/hero/heroimg.png"
                alt="Mobile Application"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
