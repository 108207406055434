import React from "react";

const DecoverTools = () => {
  return (
    <div>
      {" "}
      <section className="discover-tools-section pt-115 rpt-95">
        <div className="tools-shape-one">
          <img
            src="/assets/images/shape/discover-tools-shape1.png"
            alt="Tools Shape One"
          />
        </div>
        <div className="tools-shape-two">
          <img
            src="/assets/images/shape/discover-tools-shape2.png"
            alt="Tools Shape Two"
          />
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-10">
              <div className="section-heading text-center mb-60 rmb-40">
                <span className="heading-title">Thách thức</span>
                <h2>
                  Giải pháp đột phá, cơ hội mới - Việc dịch chuyển tư duy trở
                  nên cần thiết.
                </h2>
              </div>
            </div>
          </div>
          <div className="tools-area">
            <div className="tool-one">
              <img src="/assets/images/tools/tool1.png" alt="Tool Image One" />
            </div>
            <div className="tool-two">
              <img src="/assets/images/tools/tool2.png" alt="Tool Image Two" />
            </div>
            <div className="tool-three">
              <img
                src="/assets/images/tools/tool3.png"
                alt="Tool Image Three"
              />
            </div>
            <div className="tool-four">
              <img src="/assets/images/tools/tool4.png" alt="Tool Image Four" />
            </div>
            <img
              src="/assets/images/tools/discover-tools.png"
              alt="Discover Tools Image"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default DecoverTools;
