import React from "react";

const BlogDetailSidebar = () => {
  return (
    <div className="blog-sidebar">
      <div className="sidebar-widget search-widget">
        <h5 className="sidebar-title">Tìm kiếm bài viết</h5>
        <form method="post" action="#">
          <input
            type="search"
            name="search-field"
            defaultValue=""
            placeholder="Nhập để tìm kiếm..."
            required=""
          />
          <button type="submit">
            <i className="flaticon-search"></i>
          </button>
        </form>
      </div>
      <div className="sidebar-widget catagory-widget">
        <h5 className="sidebar-title">Danh mục</h5>
        <div className="catagoris">
          <ul className="list-style-one">
            <li>
              <a href="#">
                Phát triển web<span>(25)</span>
              </a>
            </li>
            <li>
              <a href="#">
                Thiết kế đồ họa<span>(11)</span>
              </a>
            </li>
            <li>
              <a href="#">
                Lập trình Php <span>(17)</span>
              </a>
            </li>
            <li>
              <a href="#">
                Học Reactjs <span>(23)</span>
              </a>
            </li>
            <li>
              <a href="#">
                Thiết kế web <span>(20)</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BlogDetailSidebar;
